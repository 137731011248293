import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class TrustedBrandsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.cameosalonspa.com/trusted-brands/"}
        />
        <Grid
          className={"custom-page-brands custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"qbgbofrvohzhrksp"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"ylltgspaykgdqnbt"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ad01a904-17bb-4813-8062-b8082983ca9b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qobeqliyfovwxtwa"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={3}
          >
            <Grid.Column className={"uirvvyntgtooistt"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "61b752dc-fb4e-4bb8-9e0e-924ce1bbc4e7",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "55b9d03a-0c14-4ca2-adc1-4ac8af0c59df",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"jzbgvvzoizbswdmr"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "25fa251b-90aa-4419-97f2-0c80169fa62b",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "36af9714-cbcb-49be-af7d-9c9a4c576952",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"lxtzmcvttktfqxcn"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "d0b96ef8-6965-4b95-8db6-ec189b2a3d2f",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "8cf8aa5c-d258-4883-84e4-2a5218cf364a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"bdjvvmrogcvnggrn"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={3}
          >
            <Grid.Column className={"cbgammyqyxswvzig"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "bd6736c9-ffe2-477a-aac7-825f8e22becb",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f2901715-776b-4ecd-ad72-d79b62b5079f",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"hdctxrzvqhcstuhw"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "c4b644db-cc6e-4392-ba56-bce67e297510",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ebee10c4-35bd-4b3d-87e0-bb149916699a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"iabxdctqyexpkfbq"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "ca6fdf4b-25e4-48f6-a6b8-1763e72eaf63",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c1aba224-79d6-418e-af66-793dfe9d81bd",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"mwxfhmthxjfwbagm"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={3}
          >
            <Grid.Column className={"pugbuhiajhvpnmsw"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "0b394d3c-8827-43ff-a2ac-06af2360b68f",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "cd58746e-08bc-4718-adcc-c4d27baf0633",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"dtesofadhgfuejmf"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fd0517bf-6c32-48dd-9aba-ba64a9234070",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "92138998-7eb9-4cef-be20-32d591411d63",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"ikhfbezklnpkqzky"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "e12e1e60-1d22-4c7f-8768-310a8766582b",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "605600ee-ddc3-4e39-a4c3-39f437858e37",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29665 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
